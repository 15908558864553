$autofactBlue: #155893;
$autofactBlueSecondary: #0A3254;
$autofactOrange: #ffa401;

// Autofact custom theme
$theme-colors: (
  'autofact-primary': $autofactBlue,
  'autofact-secondary': $autofactBlueSecondary,
  'autofact-orange': $autofactOrange
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Google fonts: Roboto
@import url('https://fonts.googleapis.com/css?family=Roboto');

.field-radio-group label{
  @extend .form-check;
  @extend .form-check-inline;
}

.field-radio-group label span input {
  @extend .form-check-input;
}
.field-radio-group label span span {
  @extend .form-check-label;
}

*.form-control{
  @extend *.form-control;
  border: 1px solid #bfc9d0;
  border-radius: 3px;
}

h1,
h2,
h3,
fieldset legend{
 color: $autofactBlue;
 font-family: 'Roboto', sans-serif;
}

div.help-text-inside-div > p {
  color: #6c757d !important;
  font-style: italic;
}

.input-spinner {
  position: absolute;
  left: 50%;
  background-color: $autofactBlue;
}